module.exports =  {
    hea:{
       gwsy:'官网首页',
       gscp:'公司产品',
       faal:'方案案例',
       gywm:'关于我们',
       yyxz:'语言选择',
       ljgd:"了解更多"
    },
    foo:{
        hwsc:'海外市场',
      cplb:'产品列表',
       faal:'方案案例',
        zyj:'增氧机系列',
        gzwm:'关注我们',
        fdgzh:'富地公众号',
        zz:'制造',
        jz:'价值',
        wh:'文化',
        zzkhty:"专注客户体验 创新引导技术",
        qywh:'公司拥有一支乐业、敬业、专业的员工队伍，传承“完善小我成就大我”的团队协作精神，坚持“严管理”求创新、高品质、拓市场”的经营方针，秉承“您所想的就是我们所做”的经营理念我们一贯坚持高品质，优质服务合理价格，快速的交货期，信守客户承诺的企业宗旨',
    }
}