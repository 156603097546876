import axios from "axios";
import {
	MessageBox,
	Message
} from "element-ui";
import store from '../store/store';

// import store from '@/store'
// import { getToken } from '@/utils/auth'
// 创建一个axios实例

let config = {
	timeout: 60000 // 请求超时
}
if (process.env.NODE_ENV === 'development') {
	config.baseURL = ""; // url = base url + request url
} else if (process.env.NODE_ENV === 'production') {
	config.baseURL = "http://www.futi.com/"; // url = base url + request url
}
const service = axios.create(config);
// 请求拦截器
service.interceptors.request.use(
	config => {
		// 在发送请求之前数据处理

		// if (store.getters.token) {
		//   // 让每个请求携带token
		//   // ['X-Token'] 是自定义标题键
		//   // 请根据实际情况修改
		config.headers['Lang'] = store.state.lang
		// }
		return config;
	},
	error => {
		// 处理请求错误
		console.log(error); // for debug
		return Promise.reject(error);
	}
);

// 响应拦截器
service.interceptors.response.use(
	/**
	 * 如果您想获得http信息，如头信息或状态信息
	 * 使用 return  response => response
	 */

	/**
	 * 通过自定义代码确定请求状态
	 * 这只是一个例子
	 * 您还可以通过HTTP状态码来判断状态
	 */
	response => {
		const res = response.data;
		//如果自定义代码不是20000，则判断为错误。
		if (res.code !== 1) {
			Message({
				message: res.msg || "Error",
				type: "error",
				duration: 5 * 1000
			});

			// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
			if (
				res.status === 50008 ||
				res.status === 50012 ||
				res.status === 50014
			) {
				// 重新登陆
				MessageBox.confirm(
					"您已经退出登录，您可以取消以停留在此页面，或再次登录",
					"确认退出", {
						confirmButtonText: "重新登入",
						cancelButtonText: "取消",
						type: "warning"
					}
				).then(() => {
					// store.dispatch('user/resetToken').then(() => {
					//   location.reload()
					// })
				});
			}
			return Promise.reject(new Error(res.msg || "Error"));
		} else {
			return res;
		}
	},
	error => {
		console.log("err" + error); // for debug
		Message({
			message: error.msg,
			type: "error",
			duration: 5 * 1000
		});
		return Promise.reject(error);
	}
);

export default service;
