import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../view/index';
import anli from '../view/anli.vue';
import gongsi from '../view/gongsi.vue';
import about from '../view/about.vue'
Vue.use(VueRouter)

const routes = [
  {
    path:'*',
    redirect:'/index',
  },
  {
    path: '/index',
    name: 'index',
    component: index,
  },
  {
    path:'/anli',
    name:'anli',
    component:anli,
  },
  {
    path:'/gongsi',
    name:'gongsi',
    component:gongsi,
  },
  {
    path:'/about',
    name:'about',
    component:about,
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})





export default router
