module.exports = {
	hea: {
		gwsy: 'index',
		gscp: 'products',
		faal: 'case',
		gywm: 'about',
		yyxz: 'Language',
		ljgd: "more"
	},
	foo: {
		hwsc: 'overseas market',
		cplb: 'Product list',
		faal: 'Case study',
		zyj: 'Aerator series',
		gzwm: 'Focus on us',
		fdgzh: 'official account',
		zz: 'Manufacture',
		jz: 'Value',
		wh: 'Culture',
		zzkhty: "Focus on customers’ experience, innovation advancing technology development",
		qywh: 'The company has a happy, dedicated and professional staff team, who inherit "perfect oneself to be excellent " team cooperation spirit, adhere to the " strict management,innovation, high quality,  market extension"operation policy, adhere to the" do exactly what customer may consider "business philosophy.We always provide high quality products, with excellent service,reasonable price and fast delivery,abide by customers’ commitment as our enterprise purpose.',
	}
}
