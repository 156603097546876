<template>
  <div class="tiyan">
    <div class="foot-box">
      <img src="../assets/foot1.png" alt="" class="foot-img" />
      <div class="quan">
        <div class="quan-box">
          <div class="quan-item">{{$t("foo.zz")}}</div>
          <div class="quan-item">{{$t("foo.jz")}}</div>
          <div class="quan-item">{{$t("foo.wh")}}</div>
        </div>
      </div>
      <div class="tit-box">
        <div class="tit">{{$t("foo.zzkhty")}}</div>
      </div>
      <div class="tit-item">
        <div class="item-tit">
        {{$t("foo.qywh")}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import i18n from "../lang/i18n";
export default {
data(){
  return{
//  zz:'',
//   jz:'',
//   wh:'',
//   zzkhty:'',
//   qywh:'',
  }
   
}  ,
created(){
//   this.zz=i18n.t("foo.zz"),
//   this.jz=i18n.t("foo.jz"),
//  this.wh=i18n.t("foo.wh"),
//   this.zzkhty=i18n.t("foo.zzkhty"),
//   this.qywh=i18n.t("foo.qywh")
}
  
};
</script>
<style scoped lang='scss'>
.tiyan {
    max-width: 1280px;
    min-width: 980px;
    margin: 0 auto;
  // padding: 0 100px;
  .foot-box {
    position: relative;
    .foot-img {
      height: 456px;
      width: 100%;
    }
    .quan {
      width: 100%;
      position: absolute;
      top: 88px;
      left: 0;
      .quan-box {
        padding: 0 100px;
        min-width: 1360px;
        max-width: 1980px;
        display: flex;
        justify-content: center;
        .quan-item {
          width: 84px;
          height: 84px;
          border: 5px solid #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 21px;
          font-weight: 400;
          color: #ffffff;
          margin: 0 23px;
        }
      }
    }
    .tit-box {
      width: 100%;
      position: absolute;
      top: 223px;
      left: 0;
      .tit {
        padding: 0 100px;
        min-width: 1360px;
        max-width: 1980px;

        text-align: center;
        font-size: 48px;
        font-family: FZCuHeiSongS-B-GB;
        font-weight: 400;
        color: #ffffff;
        background: linear-gradient(
          0deg,
          #959696 0.2685546875%,
          #959696 40.9912109375%,
          #ffffff 47.412109375%,
          #ffffff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .tit-item {
      width: 100%;
      position: absolute;
      top: 302px;
      left: 0;
      .item-tit {
        padding: 0 100px;
        width: 961px;
        min-width: 1024px;
        max-width: 1980px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin: 0 auto;
        line-height: 30px;
      }
    }
  }
}
</style>