<template>
	<div class="content">
		<div class="about-box">
			<div class="title">{{ goodsinfo.title }}</div>
			<div class="content2" v-html="goodsinfo.content"></div>
		</div>
	</div>
</template>

<script>
import { goodsinfo } from '../request/api';
export default {
	components: {},
	data() {
		return {
			id: this.$route.query.id ? this.$route.query.id : '',
			goodsinfo: {}
		};
	},
	computed: {
		listData() {
			return this.$store.state.lang;
		}
	},
	watch: {
		listData(newVal, oldVal) {
			this.id = this.$route.query.id;
			this.loadData(this.id);
		}
	},
	created() {
		this.loadData(this.id);
		this.$store.commit('updateheaderindex', 1);
		document.querySelector('.int').scrollTop = 0;
	},
	methods: {
		loadData(id) {
			goodsinfo({}, id).then(({ data }) => {
				console.log(data);
				this.goodsinfo = data;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
/deep/ .content2 img{
	max-width: 100%;
}
.content {
	line-height: 1;
	padding-top: 80px;
	.about-box {
		max-width: 1280px;
		min-width: 980px;
		padding: 0;
		margin: 0 auto;
		.title {
			font-size: 38px;
			font-family: Alibaba PuHuiTi;
			font-weight: 800;
			color: #000000;
			margin: 80px auto 80px;
			// border-bottom: 4px solid #000000;
		}
		.content2 {
			text-align: left;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 35px;
			// padding: 0 100px;
		}
	}
}
</style>
