module.exports = {
	hea: {
		gwsy: 'Hogar',
		gscp: 'Productos',
		faal: 'Caso',
		gywm: 'Sobre nosotros',
		yyxz: 'idioma',
		ljgd: "Aprender más"
	},
	foo: {
		hwsc: 'Mercado exterior',
		cplb: 'Lista de productos',
		faal: 'Caso de solución',
		zyj: 'Serie de aireadores',
		gzwm: 'Síguenos',
		fdgzh: 'Cuenta oficial de Fordy',
		zz: 'Fabricación',
		jz: 'Valor',
		wh: 'Cultura',
		zzkhty: "Centrarse en la experiencia del cliente e innovar la tecnología de guía",
		qywh: 'La empresa cuenta con un equipo de personal contento, dedicado y profesional, y hereda el espíritu de equipo de "perfeccionar al individuo y lograr la mejor empresa". Se adhiere a la política comercial de "gestión estricta, innovación, alta calidad y expansión del mercado", con la filosofía empresarial de "lo que usted piensa es lo que hacemos". Siempre nos hemos adherido al principio corporativo de "alta calidad, excelente servicio, precio razonable, entrega rápida y cumplimiento de las promesas del cliente".',
	}
}
