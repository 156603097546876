<template>

  <div class="foot">
    <div class="foot-left">
      <img src="../assets/logo.png" alt="" class="left-img" />
    </div>
    <div class="foot-center">
      <div class="center-item">地址:中国.浙江省台州市路桥区卖芝桥东路东段</div>
      <div class="center-item">
        ADD: East Sector Maizhiqiao East Road, Luqiao District,Taizhou City,
        Zhejiang, China
      </div>
      <div class="center-item">内销部电话: 0576-80299888 82880313 82872222</div>
      <div class="center-item">内销部传真: 0576-82881955</div>
      <div class="center-item">
        Overseas Sales Telephone: 0086 -576 -82882955 82881009
      </div>
      <div class="center-item">Overseas Fax: 0086-576-82889288</div>
      <div class="center-item">邮编: 318050 P.С.: 318050</div>
      <div class="center-item">E-mail: futi@futi.com futi@vip sina.com</div>
    </div>
    <div class="foot-right">
      <div class="right-item">
        <div class="right-tit">{{$t("foo.gzwm")}}</div>
        <div class="right-img">
          <img src="../assets/code1.png" alt="" class="img-right" />
        </div>
        <div class="right-foot">{{$t("foo.fdgzh")}}</div>
      </div>
      <div class="right-item">
        <div class="right-tit">{{$t("foo.gzwm")}}</div>
        <div class="right-img">
          <img src="../assets/code2.png" alt="" class="img-right" />
        </div>
        <div class="right-foot">{{$t("foo.fdgzh")}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import i18n from "../lang/i18n";
export default {
  data(){
    return{
      // gzwm:'',
      // fdgzh:'',
      // gzwm:'',
      // fdgzh:'',
    }
  },
  created(){
    // this.gzwm = i18n.t(foo.gzwm),
    //    this.fdgzh=i18n.t(foo.fdgzh),
    //    this.gzwm=i18n.t(foo.gzwm),
    //    this.fdgzh=i18n.t(foo.fdgzh)
  }
};
</script>

<style lang="scss" scoped>

.foot {
     max-width: 1280px;
    min-width: 980px;
  // height: 390px;
  margin:0 auto;
  display: flex;
  padding: 80px 0px 80px;
  justify-content: space-between;
  .foot-left {
    width: 368px;
    height: 43px;
    // margin-right: 80px;
    .left-img {
      width: 368px;
      height: 43px;
    }
  }
  .foot-center {
    // width: 558px;
    // min-width: 558px;
    height: 213px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #101010;
    line-height: 28px;
    margin-right: 24px;
    margin-left: 24px;
    text-align: left;

  }
  .foot-right {
    display: flex;
    .right-item {
      text-align: center;
      .right-tit {
        font-size: 23px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
      }
      .right-img {
        width: 124px;
        height: 124px;
        border: 1px dashed #000000;
        margin: 13px 0;
        .img-right {
          width: 100%;
          height: 100%;
        }
      }
      .right-foot {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
      }
    }
    .right-item:nth-child(1) {
      margin-right: 54px;
    }
  }
}
</style>