<template>
	<div id="header" @mouseleave="drawer = false">
		<div class="top">
			<div class="top-left" @click="navto('/index')"><img src="../assets/logo.png" alt="" class="logo" /></div>
			<ul class="top-right">
				<li @click="navto('/index')" class="item" :class="{ active: navIndex == 0 }" @mouseenter="join(0)">{{ $t('hea.gwsy') }}</li>
				<li class="item" :class="{ active: navIndex == 1 }" @mouseenter="join(1)">{{ $t('hea.gscp') }}</li>
				<li class="item" :class="{ active: navIndex == 2 }" @mouseenter="join(2)">{{ $t('hea.faal') }}</li>
				<li class="item" :class="{ active: navIndex == 3 }" @mouseenter="join(3)">{{ $t('hea.gywm') }}</li>

				<li class="item" @mouseenter="showChangeLang()">
					<el-dropdown @command="handleCommand" class="yyqh">
						<span class="el-dropdown-link">
							{{ $t('hea.yyxz') }}
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="zh_cn">中文</el-dropdown-item>
							<el-dropdown-item command="en">english</el-dropdown-item>
							<el-dropdown-item command="es">España</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</li>
			</ul>
		</div>
		<el-drawer class="titleTabBox" v-if="navIndex > 0" :modal="true" :visible.sync="drawer" direction="ttb" size="700px" :with-header="false">
			<div class="anli-box">
				<div class="box-top">
					<div class="top-left">{{ title }}</div>
					<div class="top-right"><i class="el-icon-close" @click="drawer = false"></i></div>
				</div>
				<div class="top-center">
					<div class="center-left">
						<div class="left-item">
							<div class="item-txt" v-for="(item, index) in arrayList[navIndex - 1].list" :key="index">
								<div class="txt" :class="{ active1: index == txtindex }" @mouseenter="avt(item.id, index)">{{ item.name }}</div>
							</div>
						</div>
						<div class="right-item">
							<div class="item-txt" v-for="(item, index) in arrayList[navIndex - 1].list[txtindex].list" :key="index">
								<div class="txt" @mouseenter="actr(item)" @click="tonext()">{{ item.title }}</div>
							</div>
						</div>
					</div>
					<div class="center-right" @click="tonext()">
						<img :src="info.coverimage ? info.coverimage : require('../assets/loading.png')" alt="" class="right-img" />
						<!-- <el-image
      style="width: 338px; height: 338px; background: #ffffff;
          border: 2px solid #101010;
          border-radius: 20px;cursor:pointer "
      :src="info.coverimage?info.coverimage:require('../assets/loading.png')"
      :fit="fit"></el-image> -->
						<div class="right-tit">{{ info.title }}</div>
						<div class="right-more">{{ $t('hea.ljgd') }}</div>
					</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import i18n from '../lang/i18n';
import { goodslist, allist, fllist, aboutlist } from '../request/api';
export default {
	name: 'Header',
	components: {},
	// props: {
	//   tabindex: {
	//     type: Number,
	//     default: 0,
	//   },
	// },
	created() {
		this.loadData();
	},
	data() {
		return {
			txtindex: 0,
			checkedIndex: 0, //当前悬浮选中的导航栏对象
			fit: 'fill',
			drawer: true,
			title: '',
			heitemlist: [],
			heurllist1: [],
			heallist: [],
			leftlist: [
				{
					image: ''
				}
			],
			rightlist: [],
			info: {},
			joinnum: '',
			arrayList: [
				{
					type: '公司产品',
					loding: false, //判断是否加载中
					more: false, //判断是否还有数据
					list: [
						{
							list: []
						}
					]
				},
				{
					type: '案例方案',
					loding: false, //判断是否加载中
					more: false, //判断是否还有数据
					list: [
						{
							list: []
						}
					]
				},
				{
					type: '关于我们',
					loding: false, //判断是否加载中
					more: false, //判断是否还有数据
					list: [
						{
							list: []
						}
					]
				}
			],
			navIndex: 0 //当前选中的nav头部对象
		};
	},
	computed: {
		productss() {
			return this.$store.state.headerindex;
		}
	},

	methods: {
		handleCommand(command) {
			this.$store.commit('changelang', command);
			console.log(this.$store.state.lang);
			this.loadData();
			this.$i18n.locale = command;
		},
		tonext() {
			if (this.title == i18n.t('hea.gscp')) {
				if (this.$route.path !== '/gongsi' && this.info.id) {
					this.$router.push({
						path: '/gongsi',
						query: { id: this.info.id }
					});
					this.drawer = false;
				} else {
					this.$router.push({
						path: '/gongsi',
						query: { id: this.info.id }
					});
					this.$router.go(0);
				}
			}
			if (this.title == i18n.t('hea.faal')) {
				if (this.$route.path !== '/anli' && this.info.id) {
					this.$router.push({
						path: '/anli',
						query: { id: this.info.id }
					});
					this.drawer = false;
				} else {
					this.$router.push({
						path: '/anli',
						query: { id: this.info.id }
					});
					this.$router.go(0);
				}
			}
			if (this.title == i18n.t('hea.gywm')) {
				if (this.$route.path !== '/about' && this.info.id) {
					this.$router.push({
						path: '/about',
						query: { id: this.info.id }
					});
					this.drawer = false;
				} else {
					this.$router.push({
						path: '/about',
						query: { id: this.info.id }
					});
					this.$router.go(0);
				}
			}
		},
		// 处理选中的列表数据
		actr(item) {
			this.info = item;
		},
		// 处理选中的数据
		avt(id, index) {
			// 保存当前选中的左侧列表的下标
			this.txtindex = index;
			// 获取请求对象数据
			let item = this.arrayList[this.navIndex - 1].list[index];
			// 判断数据是否已经贾在国
			if (item.more) {
				// 判断是否不存在数据
				if (!item.list[0]) {
					item.list[0] = {};
				}
				this.info = item.list[0];
				return;
			}
			// 判断是否数据已经加载完毕或者数据读取中你
			if (item.loding) {
				return;
			}
			// 保存请求数据
			this.getGoodslist(item);
		},
		// 请求分类数据处理
		callBackData(item, data) {
			item.loading = false; //判断是否加载中
			item.more = true; //判断是否加载中
			if (data.list.length > 0) {
				console.log(data, '处理数据回调');
				item.list = data.list.map(e => {
					e.coverimage = 'http://fudi.liuniu946.com' + e.coverimage;
					return e;
				});
				this.info = item.list[0];
			} else {
				this.info = {};
			}
		},
		loadData() {
			this.arrayList=[
				{
					type: '公司产品',
					loding: false, //判断是否加载中
					more: false, //判断是否还有数据
					list: [
						{
							list: []
						}
					]
				},
				{
					type: '案例方案',
					loding: false, //判断是否加载中
					more: false, //判断是否还有数据
					list: [
						{
							list: []
						}
					]
				},
				{
					type: '关于我们',
					loding: false, //判断是否加载中
					more: false, //判断是否还有数据
					list: [
						{
							list: []
						}
					]
				}
			];
			goodslist({
				category_id: 19
			}).then(({ data }) => {
				data.list.map(e => {
					e.coverimage = 'http://fudi.liuniu946.com' + e.coverimage;
					this.heitemlist.push(e);
				});
			});
			goodslist({
				category_id: 20
			}).then(({ data }) => {
				data.list.map(e => {
					e.coverimage = 'http://fudi.liuniu946.com' + e.coverimage;
					this.heurllist1.push(e);
				});
			});
			allist({}).then(({ data }) => {
				data.list.map(e => {
					e.coverimage = 'http://fudi.liuniu946.com' + e.coverimage;
					this.heallist.push(e);
				});
			});
		},
		/* 头部数据选择处理 */
		join(e) {
			let obj = this;
			// 判断是否需要展开下拉
			obj.drawer = true;
			// 防止重复加载
			if (obj.navIndex == e) {
				return;
			}
			// 保存头部选中的导航数据
			obj.navIndex = e;
			// 初始化选中的左侧列表index下标
			obj.txtindex = 0;
			// 更新导航index数据
			obj.$store.commit('updateheaderindex', e);
			// 判断是否分类加载数据
			if (e !== 0) {
				obj.info = {}; //清空详细选中对象
				let type = ''; //保存要查询的分分类
				if (e == 1) {
					obj.title = i18n.t('hea.gscp');
					type = 'product';
				}
				if (e == 2) {
					obj.title = i18n.t('hea.faal');
					type = 'cases';
				}
				if (e == 3) {
					obj.title = i18n.t('hea.gywm');
					type = 'about';
				}
				obj.getFllist(type, e - 1);
			}
		},
		showChangeLang() {
			this.drawer = false;
		},
		/* 获取大分类数据 */
		getFllist(type, ind) {
			console.log(type, ind);
			// 获取当前要取得的数据对象
			let dataList = this.arrayList[ind];
			// 判断是否数据已经加载完毕或者数据读取中你
			if (dataList.loding) {
				return;
			}
			// 如果已经加载过数据初始化第一个数据
			if (dataList.more) {
				if (!dataList.list[0].list[0]) {
					dataList.list[0].list[0] = {};
				}
				console.log(dataList.list, '已加载数据1');
				console.log(dataList.list[0].list, '已加载数据2');
				this.info = dataList.list[0].list[0];
				return;
			}
			console.log(dataList, '选中的对象');
			// 设置当前数据加载中
			dataList.loding = true;
			fllist({}, type)
				.then(({ data }) => {
					try {
						console.log(dataList, '左侧列表');
						// 设置数据加载完毕
						dataList.loding = false;
						// 处理数据
						dataList.list = data.map(e => {
							e.image = 'http://fudi.liuniu946.com' + e.image;
							e.list = []; //保存数据列表
							e.more = false; //判断数据是否已经加载
							e.loding = false; //判断是否加载中
							return e;
						});
						// 设置数据已经加载
						dataList.more = true;
						if (dataList.list.length > 0) {
							let item = dataList.list[0];
							this.getGoodslist(item);
						}
					} catch (e) {
						console.log(e);
					}
				})
				.catch(e => {
					dataList.loding = false;
				});
		},
		// 获取子分类数据列表
		getGoodslist(item) {
			// 保存查询的数据对象
			let pushObj = {
				category_id: item.id,
				page: 1,
				limit: 100
			};
			// 清空数据
			item.list = [];
			item.loading = true; //判断是否加载中
			if (this.navIndex == 1) {
				goodslist(pushObj)
					.then(({ data }) => {
						this.callBackData(item, data);
					})
					.catch(e => {
						item.loading = false; //判断是否加载中
					});
			}
			if (this.navIndex == 2) {
				allist(pushObj)
					.then(({ data }) => {
						this.callBackData(item, data);
					})
					.catch(e => {
						item.loading = false; //判断是否加载中
					});
			}
			if (this.navIndex == 3) {
				aboutlist(pushObj)
					.then(({ data }) => {
						this.callBackData(item, data);
					})
					.catch(e => {
						item.loading = false; //判断是否加载中
					});
			}
		},
		navto(src) {
			console.log(src);
			if (this.$route.path !== src) {
				this.$router.push({
					path: src
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.titleTabBox {
	top: 70px !important;
	// z-index: 2500 !important;
	// pointer-events: none;
}
#header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	background-color: #ffffff;
	border-bottom: 1px solid #cccccc;
	li {
		list-style: none;
	}

	.el-drawer__header {
		display: none;
	}

	// #app /deep/ .darwer {
	//   height: 800px;
	// }
	.anli-box {
		height: 550px;
		background-color: #fff;
		max-width: 1280px;
		min-width: 980px;
		margin: 0 auto;
		padding: 20px;
		.box-top {
			display: flex;
			justify-content: space-between;
			// padding: 0 100px;
			.top-left {
				font-size: 34px;
				font-weight: bold;
				color: #101010;
			}

			.top-right {
				width: 20px;
				height: 20px;
				font-size: 38px;
				margin-right: 40px;
			}
		}

		.top-center {
			display: flex;
			justify-content: space-between;
			// padding: 0 100px;
			margin-top: 25px;
			.center-left {
				flex: 1;
				display: flex;
				text-align: left;
				.left-item {
					flex: 1;
					.item-txt {
						font-size: 20px;
						font-weight: 400;
						color: #101010;
						display: flex;
						line-height: 40px;
						.txt {
							border-bottom: 2px solid rgba(0, 0, 0, 0);
							cursor: pointer;
							&.active1 {
								font-weight: 700;
							}
						}
					}
				}
				.right-item {
					flex: 1;
					height: 500px;
					overflow: auto;
					.item-txt {
						font-size: 20px;
						font-weight: bold;
						color: #101010;
						display: flex;
						line-height: 40px;
						.txt {
							border-bottom: 2px solid rgba(0, 0, 0, 0);
							cursor: pointer;
						}
						.txt:hover {
							border-bottom: 2px solid #000000;
						}
					}
				}
			}

			.center-right {
				border-left: 2px solid #e3e3e3;
				width: 460px;
				padding-left: 85px;
				text-align: left;
				.right-img {
					width: 338px;
					height: auto;
					// height: 338px;
					background: #ffffff;
					// border: 2px solid #101010;
					border-radius: 20px;
				}

				.right-tit {
					text-align: left;
					font-size: 20px;
					font-weight: bold;
					color: #101010;
					margin-top: 15px;
				}

				.right-more {
					width: 65px;
					padding-bottom: 5px;
					font-size: 16px;
					font-weight: bold;
					color: #101010;
					margin-top: 5px;
					border-bottom: 2px solid #000000;
				}
			}
		}
	}

	.top {
		height: 70px;
		max-width: 1280px;
		min-width: 980px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		// padding: 0 100px;

		.top-left {
			width: 100px;
			height: 50px;
			cursor: pointer;

			.logo {
				width: 368px;
				height: 50px;
			}
		}

		.top-right {
			display: flex;
			align-items: center;
			margin-bottom: 0 !important;
			.item {
				font-size: 15px;
				font-weight: bold;
				color: #101010;
				margin-left: 30px;
				padding: 5px 10px;
				position: relative;
				cursor: pointer;
				/deep/ .el-dropdown-menu {
					z-index: 9999 !important;
				}
				.el-dropdown {
					color: #101010;
					font-size: 15px;
				}

				&.el-dropdown-link {
					color: #ffffff;
				}

				&.active {
					color: #ffffff;
					background: #000000;
					border-radius: 16px;

					.el-dropdown {
						color: #ffffff;
						font-size: 15px;
					}
				}
			}
		}
	}
}
.el-dropdown-menu.el-popper {
	z-index: 999999 !important;
}
</style>
