import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    headerindex:0,
    lang:'zh_cn'
  },
  mutations: {
    updateheaderindex(state,num){
      state.headerindex = num
    },
    changelang(state,lang){
      state.lang = lang
    }
  },
  actions: {},
  modules: {}
});
