<template>
	<div id="app">
		<div class="int">
			<div class="content">
				<Header />
				<router-view />
				<Tiyan />
				<Footer />
			</div>
		</div>
	</div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/foot.vue';
import Tiyan from '@/components/tiyan.vue';
export default {
	components: {
		Header,
		Footer,
		Tiyan
	}
};

</script>

<style lang="scss">
body {
	margin: 0;
	overflow: hidden;
	height: 100vh;
}
#app {
	height: 100vh;
	max-width: 1700px;
	min-width: 1200px;
	margin:  0 auto;
}
.int {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
body::-webkit-scrollbar {
	display: none;
}
body {
	-ms-overflow-style: none; 
	overflow: -moz-scrollbars-none;
	overflow: overlay ;
	
}
#app::-webkit-scrollbar {
	display: none;
}
#app {
	-ms-overflow-style: none; 
	overflow: -moz-scrollbars-none;
	overflow: overlay ;
	
}
.content {
	max-width: 1700px;
	min-width: 1200px;
	margin: 0 auto;
}
#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
* {
	box-sizing: border-box;
}
</style>
