import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import es from "./es";
import zh_cn from './zh_cn'

Vue.use(VueI18n);

const messages = {
  en: en,
  es: es,
  zh_cn:zh_cn
};

const i18n = new VueI18n({
  locale: "zh_cn",
  messages
});

export default i18n;

