import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store/store'
import i18n from "./lang/i18n";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'; // or 'ant-design-vue/dist/antd.less'
Vue.use(Antd);
Vue.use(ElementUI);
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {    
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next();
  console.log('重新')
})


new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app')
