<template>
	<div class="content">
		<div class="indexbody">
			<!-- <Header :tabindex.sync="tabindex"></Header> -->
			<div class="bg-box">
				<el-carousel class="banners" @change="changeZYJ">
					<el-carousel-item v-for="(item, index) in banners" :key="index">
						<el-image class="bannerImg" :src="item.coverimage" :fit="fit"></el-image>
					</el-carousel-item>
				</el-carousel>
			</div>
			<!-- <div class="hot">{{ $t('foo.zyj') }}</div> -->
			<!-- <div class="hot1">
        <div class="hot2">Hot products</div>
      </div> -->
			<!-- 增氧机开始 -->
			<!-- <div class="navbar">
				<div style="width: 100%">
					<a-tabs :activeKey="itemindex" :tab-position="mode" @prevClick="callback" @nextClick="callback" @tabClick="openGetData">
						<a-tab-pane v-for="(item, index) in itemlist" :key="index" :tab="item.name"></a-tab-pane>
					</a-tabs>
				</div>
			</div> -->
			<!-- <div class="bg2-box">
				<el-carousel
					ref="carouselNav"
					class="hideBox"
					:initial-index="itemindex"
					@change="changeItemindex"
					:autoplay="itemlistimg.length > 0 ? (itemlistimg[itemindex].length <= 1 ): false"
				>
					<el-carousel-item v-for="(ls, indexs) in itemlist" :key="indexs">
						<el-carousel @change="changeZYJ" :autoplay="itemlistimg[indexs].length > 1 && itemindex == indexs">
							<el-carousel-item v-for="(item, index) in itemlistimg[indexs]" :key="index">
								<div @click="toinfo(item.id)">
									<el-image
										style="
										  max-width: 1280px;
										  min-width: $minWidth;
										  cursor: pointer;
										  height:100%
										"
										:src="item.coverimage"
										:fit="fit"
									></el-image>
									<div class="more-foot">{{ item.title }}</div>
									<div class="more-box">
										<div class="more">
											{{ $t('hea.ljgd') }}
											<i class="el-icon-top-right"></i>
										</div>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</el-carousel-item>
				</el-carousel>
			</div> -->
			<!-- 增氧机结束 -->
			<!-- 产品列表 -->

			<div class="hot">{{ $t('foo.cplb') }}</div>
			<div class="title-box">
				<div class="title" v-for="(item, index) in itemlist" :key="index" @click="urltop(index)" :class="{ active: itemindex == index }">{{ item.name }}</div>
			</div>

			<div class="bg3-box">
				<!-- <div class="title-tit">{{ $t('foo.cplb') }}</div> -->
				<!-- <el-carousel
					ref="carouselNav"
					class="hideBox"
					:initial-index="itemindex"
					@change="changeItemindex"
					:autoplay="itemlistimg.length > 0 ? itemlistimg[itemindex].length <= 1 : false"
				> -->
				<el-carousel ref="carouselNav" class="hideBox" :initial-index="itemindex" @change="changeItemindex" :autoplay="false">
					<el-carousel-item v-for="(ls, indexs) in itemlist" :key="indexs">
						<el-carousel @change="changeZYJ" :autoplay="itemlistimg[indexs] ? itemlistimg[indexs].length > 1 : false && itemindex == indexs">
							<el-carousel-item v-for="(item, index) in itemlistimg[indexs]" :key="index">
								<div @click="toinfo(item.id)">
									<el-image class="bannerImg" :src="item.coverimage" :fit="fit"></el-image>
									<div class="more-foot">{{ item.title }}</div>
									<div class="more-box">
										<div class="more">
											{{ $t('hea.ljgd') }}
											<i class="el-icon-top-right"></i>
										</div>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</el-carousel-item>
				</el-carousel>
				<!-- <el-carousel ref="carousel1" @change="changeUrlindex">
					<el-carousel-item v-for="(item, index) in urllist1" :key="index">
						<el-image
							style="
                cursor: pointer;
				max-width: 1280px;
				min-width: $minWidth;
				height:100%
              "
							:src="item.coverimage"
							:fit="fit"
						></el-image>
						<div class="more-box">
							<div class="more" @click="more(item.id)">
								{{ $t('hea.ljgd') }}
								<i class="el-icon-top-right"></i>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel> -->
			</div>
			<!-- 产品列表end -->
			<!-- 方案案例 -->
			<div class="bg3-box">
				<el-carousel trigger="click">
					<el-carousel-item v-for="(item, index) in allist" :key="index">
						<el-image class='bannerImg' :src="item.coverimage" :fit="fit"></el-image>
						<div class="fa-box">
							<div class="fa-top">{{ $t('foo.faal') }}</div>
							<!-- <div class="fa-foot">{{ item.title }}</div> -->
						</div>
						<div class="more-foot">{{ item.title }}</div>
						<div class="more-box">
							<div class="more" @click="almore(item.id)">
								{{ $t('hea.ljgd') }}
								<i class="el-icon-top-right"></i>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<!-- 方案案例end -->
			<div class="banner-box"></div>
			<div class="hot">{{ $t('foo.hwsc') }}</div>
			<!-- <div class="hot1">
        <div class="hot2">overseas market</div>
      </div> -->
			<div class="shichang">
				<div class="sc-left"><img :src="alinfo.coverimage" alt="" class="sc-left" /></div>
				<div class="sc-right">
					<div class="right-tit" v-html="alinfo.content"></div>
					<div class="more" @click="abmore(alinfo.id)">
						{{ $t('hea.ljgd') }}
						<i class="el-icon-top-right"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { index, goodslist, allist, aboutlist, aboutinfo, fllist } from '../request/api';
export default {
	components: {},
	name: 'index',
	data() {
		return {
			mode: 'top',
			itemlistimg: [], //增氧机图片列表
			itemlist: [], //增氧机分类列表
			urllist: [], //轮播图列表
			urllist1: [], //产品列表
			allist: [], //案例列表
			ablist: [], //关于我们列表
			fit: 'fill',
			itemindex: 0, //当前选中的增氧机分类下标
			tabindex: 0,
			urlindex: 0,
			alinfo: {},
			banners: '',
			timeOutNum: 3000, //多少毫秒循环播放
			timeOutObj: null, //用于保存无线循环对象
			itemlistSetTime: null //用于保存滚轮图片切换对象
		};
	},
	created() {
		this.loadData();
		// 循环变更对象
		// this.timout();
	},
	computed: {
		listData() {
			return this.$store.state.lang;
		}
	},
	watch: {
		listData(newVal, oldVal) {
			//  this.id = this.$route.query.id
			this.itemlist=[];
			this.itemlistimg=[];
			this.loadData();
		}
	},
	methods: {
		// 增氧机切换
		changeZYJ(e) {
			console.log(e);
			// if (e == 0) {
			// 	if (this.itemindex + 1 < this.itemlistimg.length) {
			// 		this.itemindex++;
			// 	} else {
			// 		this.itemindex = 0;
			// 	}
			// 	this.$refs.carouselNav.setActiveItem(this.itemindex);
			// }
		},
		// timeCallBack() {
		// 	// 自动切换功能
		// 	if (this.itemindex + 1 < this.itemlist.length) {
		// 		this.itemindex++;
		// 	} else {
		// 		this.itemindex = 0;
		// 	}
		// },
		// 自动切换增氧机列表
		// timout() {
		// 	if (this.timeOutObj) {
		// 		clearInterval(this.timeOutObj);
		// 		clearTimeout(this.itemlistSetTime);
		// 	}

		// 	this.timeOutObj = setInterval(e => {
		// 		console.log(this.itemindex, this.itemlistimg);
		// 		if (this.itemlistimg[this.itemindex].length == 1) {
		// 			this.timeCallBack();
		// 		}
		// 	}, this.timeOutNum + 500);
		// },
		// 请求处理增氧机系列数据
		async getProductAll() {
			try {
				let data = (await fllist({}, 'product')).data;
				console.log(data, 'product');
				this.itemlist = data.map(e => {
					return e;
				});
				let num = 0;
				// 获取分类下所有数据
				for (var i = 0; i < this.itemlist.length; i++) {
					let arr = await this.goodsListData(this.itemlist[i].id);
					this.itemlistimg.push(arr.list);
					num++;
					if (num == this.itemlist.length) {
						Promise.resolve();
					}
				}
			} catch (e) {
				console.log(e);
				//TODO handle the exception
			}
		},
		// 获取分类数据
		goodsListData(id) {
			return new Promise((ok, erro) => {
				goodslist({
					category_id: id,
					page: 1,
					limit: 100
				})
					.then(({ data }) => {
						data.list = data.list.map(e => {
							e.coverimage = 'http://fudi.liuniu946.com' + e.bigimage;
							// this.itemlist.push(e);
							return e;
						});
						ok(data);
					})
					.catch(e => {
						erro(e);
					});
			});
		},
		// 获取增氧机列表
		openGetData(e) {
			// 循环变更对象
			// this.timout();
			this.itemindex = e;
			this.$refs.carouselNav.setActiveItem(e);
		},
		changeItemindex(e) {
			this.itemindex = e;
		},
		callback(val) {
			console.log(val);
		},
		loadData() {
			// 获取增氧机产品数据、
			this.getProductAll();

			index().then(data => {
				data.data.loopProduct.map(e => {
					e.coverimage = 'http://fudi.liuniu946.com' + e.coverimage;
				});
				let banner = data.data.banners.split(',');
				this.banners = banner.map(e => {
					e = 'http://fudi.liuniu946.com' + e;
					return {
						coverimage: e
					};
				});
				this.urllist = data.data.loopProduct;
				console.log(this.banners);
			});

			// 其他分类
			this.goodsListData(39)
				.then(e => {
					// data.list.splice(4, data.list.length - 1);
					this.urllist1 = e.list;
				})
				.catch(e => {
					console.log(e);
				});

			allist({}).then(({ data }) => {
				data.list.map(e => {
					e.coverimage = 'http://fudi.liuniu946.com' + e.coverimage;
					// this.allist.push(e);
				});
				this.allist = data.list;
			});
			aboutlist({}).then(({ data }) => {
				data.list.map(e => {
					e.coverimage = 'http://fudi.liuniu946.com' + e.coverimage;
					this.ablist.push(e);
				});
				this.ablist = data.list;
				aboutinfo({}, 8).then(({ data }) => {
					this.alinfo = data;
					this.alinfo.coverimage = 'http://fudi.liuniu946.com' + this.alinfo.coverimage;
				});
			});
		},
		// itemtop(index, item) {
		// 	this.itemindex = index;
		// 	// this.$refs.carousel.setActiveItem(index);
		// 	let pid = item.id;
		// 	console.log(pid);
		// 	this.itemlistimg = [];
		// 	// 获取增氧机下属分类
		// 	this.goodsListData(pid).then((e) => {
		// 		// data.list.splice(4, data.list.length - 1);
		// 		this.itemlistimg = e.list;
		// 	}).catch((e) => {
		// 		console.log(e);
		// 	})
		// },
		urltop(index) {
			this.itemindex = index;
			this.$refs.carouselNav.setActiveItem(index);
			// this.$refs.carousel1.setActiveItem(index);
		},
		// 产品自动切换
		changeUrlindex(e) {
			this.urlindex = e;
		},
		toinfo(id) {
			this.$router.push({
				path: '/gongsi',
				query: { id: id }
			});
		},
		more(id) {
			this.$router.push({
				path: '/gongsi',
				query: { id: id }
			});
		},
		almore(id) {
			this.$router.push({
				path: '/anli',
				query: { id: id }
			});
		},
		abmore(id) {
			this.$router.push({
				path: '/about',
				query: { id: id }
			});
		}
	}
};
</script>

<style scoped lang="scss">
$maxWidth: 1280px; //最大宽
$minWidth: 980px; //最大宽

/deep/ .ant-tabs-tab-next {
	&.ant-tabs-tab-arrow-show {
		background-color: rgba($color: #333333, $alpha: 0.1);
		.ant-tabs-tab-next-icon-target {
			font-size: 30px !important;
			color: #ffffff;
		}
	}
}
/deep/ .ant-tabs-tab-prev {
	&.ant-tabs-tab-arrow-show {
		background-color: rgba($color: #333333, $alpha: 0.1);
		.ant-tabs-tab-prev-icon-target {
			font-size: 30px !important;
			color: #ffffff;
		}
	}
}
/deep/ .ant-tabs-tab {
	font-size: 32px;
	// color: #333333;
	// &.ant-tabs-tab-active{
	// color: #333333;
	// }
}
.indexbody {
	max-width: $maxWidth;
	min-width: $minWidth;
	padding: 0;
	margin: 0 auto;
}
.content {
	line-height: 1;
	padding-top: 80px;
	.bannerImg {
		cursor: pointer;
		height: 100%;
		width: 100%;
	}
	.bg-box {
		padding-top: 44%;
		.banners {
			width: 100%;
			height: 100%;
		}
	}
	.hot {
		min-width: $minWidth;
		max-width: $maxWidth;
		font-size: 38px;
		font-weight: 800;
		color: #333333;
		margin: 80px 0 12px;
		text-align: center;
		padding: 0 100px;
	}
	.hot1 {
		min-width: $minWidth;
		max-width: $maxWidth;
		font-size: 16px;
		font-weight: normal;
		color: #5d5d5e;
		text-align: center;
		padding: 0 100px;
		.hot2 {
			width: 150px;
			// height: 13px;
			margin: 0 auto;
			border-bottom: 4px solid #333333;
			padding-bottom: 10px;
		}
	}
	.navbar {
		height: 87px;
		white-space: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
		min-width: $minWidth;
		max-width: $maxWidth;
		display: flex;
		align-items: center;
		// justify-content: center;
		// margin-top: 55px;
		// margin-bottom: 55px;
		// padding: 0 100px;
		.item {
			list-style: none;
			padding: 0;
			.item-box {
				display: inline-block;
				overflow: hidden;
				// flex: 1;
				// height: 50px;
				margin-right: 35px;
				// margin: 0 35px 0;
				font-size: 22px;
				font-weight: bold;
				color: #101010;
				padding-bottom: 3px;
				border-bottom: 2px solid rgba(0, 0, 0, 0);
				// padding-bottom: 5px;
				&.active {
					border-bottom: 2px solid #333333;
					// padding-bottom: 0px;
				}
			}
		}
	}
	.bg3-box {
		margin-top: 30px;
		/deep/ .hideBox {
			& > .el-carousel__container {
				& > .el-carousel__arrow {
					display: none !important;
				}
			}
			& > .el-carousel__indicators {
				display: none !important;
			}
		}
	}
	.bg3-box {
		.fa-box {
			width: 100%;
			position: absolute;
			top: 50px;
			left: 0;
			// padding: 0 100px;
			z-index: 999;
			.fa-top {
				font-size: 40px;
				font-family: Alibaba PuHuiTi;
				font-weight: 800;
				color: #333333;
				margin-bottom: 10px;
			}
			.fa-foot {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				line-height: 51px;
			}
		}
		.title-tit {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 30px;
			left: 0;
			// padding: 0 100px;
			z-index: 999;
			font-size: 32px;
			font-family: Alibaba PuHuiTi;
			font-weight: bold;
			color: #333333;
		}
	}
	.shichang {
		display: flex;
		// padding: 0 100px;
		margin-top: 20px;
		margin-bottom: 100px;
		.sc-left {
			width: 620px;
			height: 667px;
			margin-right: 50px;
			.sc-left {
				width: 620px;
				height: 667px;
			}
		}
		.sc-right {
			height: 667px;
			min-width: 400px;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 28px;
			text-align: left;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.right-tit {
				// padding-top: 50px;
				height: 590px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
			.more {
				width: 124px;
				height: 41px;
				background: #333333;
				border-radius: 21px;
				font-size: 16px;
				font-weight: bold;
				color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: -50px 0 0 auto;
				cursor: pointer;
			}
		}
	}
}

.bg3-box,
.bg2-box,
.bg-box {
	background-color: #e3e3e3;
	width: 100%;
	max-width: $maxWidth;
	min-width: $minWidth;
	// box-sizing:border-box;
	// height: 656px;
	// margin: 0 auto;
	position: relative;
	left: 0;
	top: 0;
	padding-top: 58%;
	/deep/ .el-carousel {
		box-sizing: border-box;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		.el-carousel__container {
			width: 100%;
			height: 100%;
		}
	}
}
.bg2-box,
.bg3-box {
	.more-box {
		width: 100%;
		position: absolute;
		bottom: 30px;
		left: 0;
		z-index: 999;
		.more {
			width: 124px;
			height: 41px;
			background: #333333;
			border-radius: 21px;
			font-size: 16px;
			font-weight: bold;
			color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0px auto;
			cursor: pointer;
		}
	}
	.more-foot {
		width: 100%;
		position: absolute;
		bottom: 100px;
		left: 0;
		z-index: 999;
		font-size: 50px;
		font-family: Alibaba PuHuiTi;
		font-weight: 800;
		color: #333333;
	}
}
.title-box {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	// position: absolute;
	// top: 30px;
	// left: 0;
	// padding: 0 100px;
	margin-top: 40px;
	z-index: 999;
	.title {
		height: 35px;
		font-size: 24px;
		font-family: Alibaba PuHuiTi;
		font-weight: bold;
		color: #333333;
		padding-bottom: 2px;
		margin: 0 auto;
		border-bottom: 2px solid rgba(0, 0, 0, 0);
		cursor: pointer;
		&.active {
			border-bottom: 4px solid #333333;
		}
	}
}
</style>
