import request from './http'

//首页接口
export function index(data) {
  return request({
    url: "/apis/index",
    method: "get",
    params: data
  });
}

//公司列表
export function gslist(data) {
  return request({
    url: "/apis/companies",
    method: "post",
    params: data
  });
}

//分类列表
export function fllist(data,type) {
  return request({
    url: "/apis/cate/"+type,
    method: "get",
    params: data
  });
}

//表单保存
export function bdbc(data) {
  return request({
    url: "/apis/form",
    method: "post",
    params: data
  });
}

//设备
export function machine(data) {
  return request({
    url: "/apis/machine",
    method: "get",
    params: data
  });
}

//公司介绍详情
export function gsinfo(data) {
  return request({
    url: "/apis/article/1",
    method: "get",
    params: data
  });
}

//公司介绍列表
export function gsinfolist(data) {
  return request({
    url: "/apis/article",
    method: "get",
    params: data
  });
}

//案例列表
export function allist(data) {
  return request({
    url: "/apis/cases",
    method: "get",
    params: data
  });
}

//案例详情
export function alinfo(data,id) {
  return request({
    url: "/apis/cases/"+id,
    method: "get",
    params: data
  });
}

//产品列表
export function goodslist(data) {
  return request({
    url: "/apis/product",
    method: "get",
    params: data
  });
}

//产品详情
export function goodsinfo(data,type) {
  return request({
    url: "/apis/product/"+type,
    method: "get",
    params: data
  });
}

//关于我们详情
export function aboutinfo(data,id) {
  return request({
    url: "/apis/about/"+id,
    method: "get",
    params: data
  });
}

//关于我们列表
export function aboutlist(data) {
  return request({
    url: "/apis/about",
    method: "get",
    params: data
  });
}

//创业详情
export function storyinfo(data) {
  return request({
    url: "/apis/about/1",
    method: "get",
    params: data
  });
}

//创业列表
export function storylist(data) {
  return request({
    url: "/apis/story",
    method: "get",
    params: data
  });
}
